import { Dialog, DialogConfig, DialogRef } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { ConfirmDialogComponent } from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private readonly _drawerConfig: DialogConfig = {
    width: '640px',
    maxWidth: '80vw',
    height: '100vh',
    minHeight: '100vh',
    maxHeight: '100vh',
    positionStrategy: this._overlay.position().global().top('0').right('0'),
  };

  constructor(private _dialog: Dialog, private _overlay: Overlay) {}

  public create<R = unknown, D = unknown, C = unknown>(
    component: ComponentType<C>,
    options: DialogConfig<D, DialogRef<R, C>> = {
      panelClass: 'dialog',
    }
  ) {
    const dialogRef = this._dialog.open(component, options);

    return dialogRef;
  }

  public confirm() {
    const dialogRef = this._dialog.open<boolean>(ConfirmDialogComponent, {
      panelClass: 'dialog',
    });

    return dialogRef;
  }

  public drawer<R = unknown, D = unknown, C = unknown>(
    component: ComponentType<C>,
    options: DialogConfig<D, DialogRef<R, C>> = {}
  ) {
    const dialogRef = this._dialog.open(
      component,
      Object.assign(this._drawerConfig, options)
    );

    return dialogRef;
  }
}
