import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagComponent } from './tag/tag.component';

@NgModule({
  declarations: [],
  imports: [CommonModule, TagComponent],
  exports: [TagComponent],
})
export class TagModule {}
