/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractControlComponent } from '../../abstract-control/abstract-control.component';
import { ToggleGroupComponent } from '../toggle-group/toggle-group.component';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrl: './toggle.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ToggleComponent,
    },
  ],
})
export class ToggleComponent extends AbstractControlComponent<any> {
  @Input() override value: any;
  @Input() on: boolean = false;
  @Output() toggle: EventEmitter<boolean> = new EventEmitter();

  constructor(@Optional() private _toggleGroup: ToggleGroupComponent) {
    super();
  }

  onToggle() {
    this.on = !this.on;
    this.onChange(this.on);
    this.toggle.emit(this.on);
    if (this._toggleGroup) {
      this._toggleGroup.onToggle(this.value);
    }
  }

  override writeValue(value: boolean): void {
    this.on = value;
  }

  isOn() {
    if (this._toggleGroup) {
      return this._toggleGroup.value.includes(this.value);
    } else {
      return this.on;
    }
  }
}
