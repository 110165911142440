<div class="image-upload">
  <div class="image-upload-image" *ngIf="value">
    <img [src]="value" width="32px" class="img-block" />
  </div>
  <div class="image-upload-button">
    <button
      class="btn btn-small btn-stroke btn-grey-100"
      (click)="fileInput.click()">
      Change project picture
    </button>
  </div>
</div>

<input type="file" #fileInput (change)="onFIleChange($event)" />
