import { Component } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractControlComponent } from '../abstract-control/abstract-control.component';
import { FilesService } from '@app/core/services/files.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-image-upload',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './image-upload.component.html',
  styleUrl: './image-upload.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ImageUploadComponent,
    },
  ],
})
export class ImageUploadComponent extends AbstractControlComponent<string> {
  constructor(private _filesService: FilesService) {
    super();
  }

  onFIleChange(event: Event) {
    const file = (event.target as HTMLInputElement).files?.[0];

    if (file) {
      this._filesService.upload('project', file).subscribe((file) => {
        this.value = file.url;
        this.onChange(this.value);
      });
    }
  }
}
