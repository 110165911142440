import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from './page/page.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { PageContentComponent } from './page-content/page-content.component';
import { PageActionsComponent } from './page-actions/page-actions.component';

@NgModule({
  declarations: [
    PageComponent,
    PageTitleComponent,
    PageContentComponent,
    PageActionsComponent,
  ],
  imports: [CommonModule],
  exports: [
    PageComponent,
    PageTitleComponent,
    PageContentComponent,
    PageActionsComponent,
  ],
})
export class PageModule {}
