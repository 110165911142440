/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractControlComponent } from '../../abstract-control/abstract-control.component';

@Component({
  selector: 'app-toggle-group',
  templateUrl: './toggle-group.component.html',
  styleUrl: './toggle-group.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ToggleGroupComponent,
    },
  ],
})
export class ToggleGroupComponent extends AbstractControlComponent<any[]> {
  constructor() {
    super();
  }

  onToggle(value: any) {
    const index = this.value.indexOf(value);

    if (index >= 0) {
      this.value.splice(index, 1);
    } else {
      this.value.push(value);
    }
    this.onChange(this.value);
  }

  override writeValue(value: any[]): void {
    this.value = value;
  }
}
