import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToggleComponent } from './toggle/toggle.component';
import { ToggleGroupComponent } from './toggle-group/toggle-group.component';

@NgModule({
  declarations: [ToggleComponent, ToggleGroupComponent],
  imports: [CommonModule],
  exports: [ToggleComponent, ToggleGroupComponent],
})
export class ToggleModule {}
