<div class="page-title">
  <div class="page-title-text">
    <ng-content select="[pageTitleText]"></ng-content>
  </div>
  <div class="page-title-content">
    <ng-content select="[pageTitleContent]"></ng-content>
  </div>
  <div class="page-title-actions">
    <ng-content select="[pageTitleActions]"></ng-content>
  </div>
</div>
