import { Component } from '@angular/core';

@Component({
  selector: 'app-page-actions',
  templateUrl: './page-actions.component.html',
  styleUrl: './page-actions.component.scss'
})
export class PageActionsComponent {

}
