import { SelectionModel } from '@angular/cdk/collections';
import { SelectComponent } from './select/select.component';
import { SelectOptionComponent } from './select-option/select-option.component';

export class SelectModel {
  private _items: SelectOptionComponent[] = [];
  private _filteredItems: SelectOptionComponent[] = [];
  constructor(
    private _select: SelectComponent,
    private _selectionModel: SelectionModel<SelectOptionComponent>
  ) {}

  public get filteredItems() {
    return this._filteredItems;
  }

  public get value() {
    return this._select.multiple
      ? this._selectionModel.selected.map((o) => o.value)
      : this._selectionModel.selected[0]?.value ?? null;
  }

  setItems(items: SelectOptionComponent[]) {
    this._items = items;
    this._filteredItems = items;
  }

  select(option: SelectOptionComponent) {
    if (!this._select.multiple) {
      this._selectionModel.clear();
      this._items.map((option) => (option.selected = false));
      this._filteredItems.map((option) => (option.selected = false));
      option.selected = true;
      this._selectionModel.select(option);
    } else {
      if (option.selected) {
        option.selected = false;
        this._selectionModel.deselect(option);
      } else {
        option.selected = true;
        this._selectionModel.select(option);
      }
    }
  }

  selectedItems() {
    return this._selectionModel.selected;
  }

  clear() {
    this._selectionModel.clear();
  }

  public findItemByValue(value: string): SelectOptionComponent | undefined {
    return this._items.find((item) => item.value == value);
  }

  mapSelectedItems() {
    for (const selected of this.selectedItems()) {
      const item = this.findItemByValue(selected.value);
      this._selectionModel.deselect(selected);
      this._selectionModel.select(item || selected);
    }
  }
}
