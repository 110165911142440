<div class="control-input">
  <input
    #input
    [autofocus]="focus"
    [type]="type"
    [(ngModel)]="value"
    (ngModelChange)="onChange($event)"
    [placeholder]="placeholder"
    class="input input-{{ size }}"
    [ngClass]="{ 'input-ghost': ghost }"
    (keydown.enter)="enter.emit(value)"
    (focusout)="onFocusOut()" />
</div>

<app-control-error [name]="formControlName"></app-control-error>
