import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { ErrorResponse } from '../models/api';

@Injectable({
  providedIn: 'root',
})
export class ErrorsService {
  private _error$: ReplaySubject<ErrorResponse | null> = new ReplaySubject();
  private _errors$: BehaviorSubject<Record<string, string | null>> =
    new BehaviorSubject({});

  constructor() {}

  public get errors$() {
    return this._errors$.asObservable();
  }
  public get error$() {
    return this._error$.asObservable();
  }

  setErrors(errors: Record<string, string>) {
    this._errors$.next(errors);
  }

  remove(key: string) {
    const value = this._errors$.value;
    value[key] = null;
    this._errors$.next(value);
  }

  clear() {
    this._errors$.next({});
  }

  setError(error: ErrorResponse | null) {
    this._error$.next(error);
  }
}
