import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog-actions',
  templateUrl: './dialog-actions.component.html',
  styleUrl: './dialog-actions.component.scss',
})
export class DialogActionsComponent {
  constructor(private _dialogRef: DialogRef) {}

  close() {
    this._dialogRef.close(false);
  }

  confirm() {
    this._dialogRef.close(true);
  }
}
