import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ControlErrorComponent } from '@app/components/control-error/control-error.component';

@Component({
  selector: 'app-control',
  standalone: true,
  imports: [CommonModule, ControlErrorComponent],
  templateUrl: './control.component.html',
  styleUrl: './control.component.scss',
})
export class ControlComponent {
  @Input() error!: string;
}
