import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ApiSuccessResponse, SuccessResponse } from '@app/core/models/api';
import { ProfileService } from '@app/core/services/profile.service';
import { ReplaySubject, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { ISignIn, ISignInResult, ISignInTokens } from './auth-model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _api = `${environment.ApiV1}/auth`;

  private _profileService = inject(ProfileService);

  private _token$: ReplaySubject<string> = new ReplaySubject();

  constructor(private _http: HttpClient, private _router: Router) {}

  auth(payload: ISignIn) {
    return this._http
      .post<ApiSuccessResponse<ISignInResult>>(this._api, payload)
      .pipe(
        tap((res) => {
          if (res.data.code) {
            this._router.navigate(['/auth', 'sign-in', res.data.code]);
            // this._setToken(res.data);
            // this._profileService.load().subscribe(() => {
            //   this._router.navigate(['/app']);
            // });
          }
        })
      );
  }

  authConfirm(data: { code: string; otp: string; deviceId: string }) {
    return this._http.post<ISignInTokens>(`${this._api}/confirm`, data).pipe(
      tap((res) => {
        this._setToken(res);
        this._profileService.load().subscribe(() => {
          this._router.navigate(['/app']);
        });
      })
    );
  }

  getAuthInfo(code: string) {
    return this._http.get<{ email: string; provider: string }>(
      `${this._api}/info/${code}`
    );
  }

  validate() {
    return this._http.get<SuccessResponse>(`${this._api}/validate`);
  }

  private _setToken(tokens: ISignInTokens) {
    this._token$.next(tokens.accessToken);
    localStorage.setItem('accessToken', tokens.accessToken);
    localStorage.setItem('refreshToken', tokens.refreshToken);
  }

  getToken(): string | null {
    return localStorage.getItem('accessToken') &&
      localStorage.getItem('accessToken') != 'undefined'
      ? localStorage.getItem('accessToken')
      : null;
  }

  googleAuth(deviceId: string) {
    const url = new URL(`${this._api}/google`);
    url.searchParams.append('deviceId', deviceId);
    window.location.href = url.toString();
  }
}
