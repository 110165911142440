import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog-title',
  templateUrl: './dialog-title.component.html',
  styleUrl: './dialog-title.component.scss',
})
export class DialogTitleComponent {
  constructor(private _dialogRef: DialogRef) {}

  close() {
    this._dialogRef.close(false);
  }
}
