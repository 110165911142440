/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-select-option',
  templateUrl: './select-option.component.html',
  styleUrl: './select-option.component.scss',
})
export class SelectOptionComponent implements OnChanges, OnDestroy {
  @Input() icon?: string;
  @Input() option: any;
  @Input() value: any;
  @Input() selected?: boolean = false;

  @Input()
  public set disabled(v: boolean) {
    this._disabled = v;
  }
  public get disabled(): boolean {
    return !!this._disabled;
  }
  private _disabled?: boolean = false;

  public changes$ = new Subject<{
    value: any;
    disabled: boolean;
    label: string;
    icon?: string;
  }>();

  constructor(private _elementRef: ElementRef<HTMLElement>) {}

  get label(): string {
    return (this._elementRef.nativeElement.textContent || '').trim();
  }

  ngOnChanges(): void {
    this.changes$.next({
      value: this.value,
      disabled: this.disabled,
      label: this.label,
      icon: this.icon,
    });
  }

  ngOnDestroy(): void {
    this.changes$.complete();
  }
}
