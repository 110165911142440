import { CdkMenuModule } from '@angular/cdk/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { DialogsModule } from './dialogs/dialogs.module';
import { PageModule } from './page/page.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgScrollbarModule,
    RouterModule,
    ReactiveFormsModule,
    PageModule,
    DialogsModule,
    OverlayModule,
    CdkMenuModule,
  ],
  exports: [
    NgScrollbarModule,
    ReactiveFormsModule,
    RouterModule,
    PageModule,
    OverlayModule,
    CdkMenuModule,
  ],
})
export class SharedModule {}
