/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IFile } from '../models/file';
import { Api, FilterParams } from '../models/api';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  private _api = `${environment.ApiV1}/files`;
  constructor(private _http: HttpClient) {}

  upload(type: 'screens' | 'project', file: File) {
    const fd = new FormData();
    fd.append('type', type);
    fd.append('file', file, file.name);

    return this._http.post<IFile>(this._api, fd);
  }

  find(filter: FilterParams<IFile> = {}) {
    return this._http.get<Api<IFile>>(this._api, { params: filter as any });
  }
}
