import { CommonModule } from '@angular/common';
import { Component, Input, Optional } from '@angular/core';
import { FormComponent } from '../form/form.component';

@Component({
  selector: 'app-control-error',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './control-error.component.html',
  styleUrl: './control-error.component.scss',
})
export class ControlErrorComponent {
  @Input() name!: string;
  constructor(@Optional() private _form: FormComponent) {}

  public get error(): string | null {
    return this._form && this.name && this._form.errors[this.name]
      ? this._form.errors[this.name]
      : null;
  }

  clear() {
    if (this.error) {
      this._form.errors[this.name] = null;
    }
  }
}
