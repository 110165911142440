import { Injectable } from '@angular/core';
import { IUser } from '../models/user';
import { ReplaySubject, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private _profile!: IUser;
  private _profile$: ReplaySubject<IUser> = new ReplaySubject();

  private _api = `${environment.ApiV1}/auth/me`;

  public get profile$() {
    return this._profile$.asObservable();
  }

  constructor(private _http: HttpClient) {}

  load() {
    return this._http.get<IUser>(this._api).pipe(
      tap((profile) => {
        this._profile = profile;
        this._profile$.next(profile);
      })
    );
  }
}
