/* eslint-disable @typescript-eslint/no-explicit-any */
import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { overlayPositionsCover } from '@app/core/data/overlay-position';
import { Subject, debounceTime, merge, startWith, takeUntil } from 'rxjs';
import { AbstractControlComponent } from '../../abstract-control/abstract-control.component';
import { SelectOptionComponent } from '../select-option/select-option.component';
import { SelectModel } from '../select.model';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectComponent,
    },
  ],
})
export class SelectComponent
  extends AbstractControlComponent<any>
  implements AfterContentInit, OnDestroy, OnInit
{
  @Input() createPlatceholder?: string;
  @Input() showCreate: boolean = false;
  @Input() showSearch: boolean = false;
  @Input() triggerTemplate!: TemplateRef<unknown>;
  @Input() optionTemplate!: TemplateRef<unknown>;
  @Input() footerTemplate!: TemplateRef<unknown>;
  @Input() multiple: boolean = false;
  @Input() size: 'small' | 'default' = 'default';

  @Output() selected: EventEmitter<any> = new EventEmitter();
  @Output() search: EventEmitter<string> = new EventEmitter();
  @Output() create: EventEmitter<string> = new EventEmitter();

  @ViewChild('searchInput') private _searchInput?: ElementRef<HTMLInputElement>;

  @ContentChildren(SelectOptionComponent)
  private _options!: QueryList<SelectOptionComponent>;

  public overlayPosition = overlayPositionsCover;
  public searchControl = new FormControl();

  private _model!: SelectModel;
  private _open: boolean = false;
  private _destroy$ = new Subject<void>();

  public get open() {
    return this._open;
  }

  public get items() {
    return this._model.filteredItems;
  }
  constructor(private _cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.overlayPosition = overlayPositionsCover;
    this._model = new SelectModel(this, new SelectionModel(this.multiple));

    this.searchControl.valueChanges
      .pipe(debounceTime(200))
      .subscribe((search) => {
        this.search.emit(search);
      });
  }

  ngAfterContentInit(): void {
    this._setItems();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _setItems() {
    const mapOptions = (options: QueryList<SelectOptionComponent>) => {
      const items = options.map((option) => option);
      this._model.setItems(items);

      if (this.value) {
        this._model.mapSelectedItems();
      }
      this._cdr.detectChanges();
    };

    const optionChange = () => {
      const changedOrDestroyed = merge(this._options.changes, this._destroy$);
      merge(...this._options.map((option) => option.changes$))
        .pipe(takeUntil(changedOrDestroyed))
        .subscribe(() => {
          this._cdr.detectChanges();
        });
    };

    this._options.changes
      .pipe(startWith(this._options), takeUntil(this._destroy$))
      .subscribe((options) => {
        mapOptions(options);
        optionChange();
      });
  }
  public onOpen() {
    this._open = true;
    setTimeout(() => {
      if (this._searchInput) {
        this._searchInput.nativeElement.focus();
      }
    }, 0);
  }

  public onClose() {
    this._open = false;
  }

  public onSelect(option: SelectOptionComponent) {
    this._model.select(option);
    if (!this.multiple) {
      this.onClose();
    }
    this.value = this.selectedValues;
    this.onChange(this.selectedValues);
    this.selected.emit(this.selectedValues);
  }

  public get selectedValues() {
    return this._model.value;
  }

  onCreate() {
    this.create.emit(this.searchControl.value);
  }

  public get hasValue(): boolean {
    return this.multiple ? !!this.value?.length : !!this.value;
  }

  public get selectedOptions() {
    return this._model.selectedItems();
  }

  override writeValue(value: any): void {
    this._model.clear();
    if (this.multiple && Array.isArray(value)) {
      value.forEach((val: any) => {
        const item = this._model.findItemByValue(val);
        if (item) {
          this.onSelect(item);
        } else {
          // @ts-ignore
          this.onSelect({ value: val, label: val });
        }
      });
    } else if (!this.multiple) {
      const item = this._model.findItemByValue(value);
      if (item) {
        this.onSelect(item);
      }
    }

    this.value = value;

    this._cdr.markForCheck();
  }
}
