/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-abstract-control',
  standalone: true,
  imports: [],
  templateUrl: './abstract-control.component.html',
  styleUrl: './abstract-control.component.scss',
})
export class AbstractControlComponent<T> implements ControlValueAccessor {
  value!: T;

  onChange = (value: T) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;

  writeValue(value: T) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  public get isString() {
    return typeof this.value === 'string';
  }
  public get isNumber() {
    return typeof this.value === 'number';
  }
}
