import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { SelectOptionComponent } from './select-option/select-option.component';
import { SelectComponent } from './select/select.component';
import { TagModule } from '@app/components/tag/tag.module';

@NgModule({
  declarations: [SelectComponent, SelectOptionComponent],
  exports: [SelectComponent, SelectOptionComponent],
  imports: [CommonModule, SharedModule, TagModule],
})
export class SelectModule {}
