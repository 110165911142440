<div class="select">
  <div
    class="select-trigger"
    #trigger
    cdk-overlay-origin
    #overlayOrigin="cdkOverlayOrigin"
    (click)="onOpen()">
    @if (!hasValue) {
    <ng-container
      *ngIf="!triggerTemplate && !showCreate"
      [ngTemplateOutlet]="defaultTriggerTmpl"></ng-container>
    <ng-container
      *ngIf="triggerTemplate && !showCreate"
      [ngTemplateOutlet]="triggerTemplate"></ng-container>
    } @else if(hasValue && !showCreate) { @if (optionTemplate) {
    <ng-container
      *ngFor="let option of selectedOptions"
      [ngTemplateOutlet]="optionTemplate"
      [ngTemplateOutletContext]="{ $implicit: option.option }"></ng-container>
    } @else {
    <app-tag
      *ngFor="let item of selectedOptions"
      color="#DEE0E9"
      colorText="#2D2E31"
      >{{ item.label || item?.option?.name }}</app-tag
    >
    } }

    <ng-container
      *ngIf="showCreate"
      [ngTemplateOutlet]="addNewItemTmpl"></ng-container>
  </div>
</div>

<ng-template
  #selectTmpl
  cdk-connected-overlay
  cdkConnectedOverlayLockPosition
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayWidth]="trigger.offsetWidth"
  [cdkConnectedOverlayOrigin]="overlayOrigin"
  [cdkConnectedOverlayOpen]="open"
  [cdkConnectedOverlayPositions]="overlayPosition"
  (backdropClick)="onClose()">
  <div class="select-container select-container-{{ size }}">
    @if (showSearch) {
    <div class="select-search">
      <input
        #searchInput
        [formControl]="searchControl"
        type="text"
        class="input-ghost"
        placeholder="Search..." />
    </div>
    }
    <ng-scrollbar [autoHeightDisabled]="false">
      <div class="select-options">
        <ng-container
          *ngFor="let option of items"
          [ngTemplateOutlet]="optionTmpl"
          [ngTemplateOutletContext]="{ $implicit: option }"></ng-container>

        @if (showCreate && searchControl.value) {
        <div class="select-option-default" (click)="onCreate()">
          <div class="select-option">
            <div class="select-option-icon">
              <div class="icon icon-plus icon-grey-300"></div>
            </div>
            <div class="select-option-label">
              Create new {{ createPlatceholder }}&nbsp;<strong>{{
                searchControl.value
              }}</strong>
            </div>
          </div>
        </div>
        }
      </div>
    </ng-scrollbar>
    @if (footerTemplate) {
    <div class="select-footer">
      <ng-container [ngTemplateOutlet]="footerTemplate"></ng-container>
    </div>
    }
  </div>
</ng-template>

<ng-template #optionTmpl let-option>
  <div class="select-option-container" (click)="onSelect(option)">
    @if (!optionTemplate) {
    <div class="select-option-default">
      <div class="select-option">
        <div class="select-option-icon" *ngIf="option.icon">
          <div class="icon icon-{{ option.icon }} icon-grey-100"></div>
        </div>
        <div class="select-option-label">
          {{ option.label }}
        </div>
      </div>
    </div>
    } @else {
    <div class="select-option-custom">
      <ng-container
        [ngTemplateOutlet]="optionTemplate"
        [ngTemplateOutletContext]="{ $implicit: option.option }"></ng-container>
    </div>
    }
  </div>
</ng-template>

<ng-template #defaultTriggerTmpl>
  <app-tag>Select</app-tag>
</ng-template>

<ng-template #addNewItemTmpl>
  <button class="btn btn-small btn-ghost btn-with-icon btn-grey-300">
    <div class="icon icon-plus icon-grey-300"></div>
    Add item
  </button>
</ng-template>
