import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlComponent } from './control/control.component';
import { InputComponent } from './input/input.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TitleControlComponent } from './title-control/title-control.component';
import { SelectModule } from './select/select.module';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { ToggleModule } from './toggle/toggle.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ControlComponent,
    InputComponent,
    MatCheckboxModule,
    TitleControlComponent,
    SelectModule,
    ImageUploadComponent,
    ToggleModule,
  ],
  exports: [
    ControlComponent,
    InputComponent,
    MatCheckboxModule,
    TitleControlComponent,
    SelectModule,
    ImageUploadComponent,
    ToggleModule,
  ],
})
export class FormControlsModule {}
