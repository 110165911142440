import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import {
  DEFAULT_DIALOG_CONFIG,
  DialogConfig,
  DialogModule,
} from '@angular/cdk/dialog';
import { DialogTitleComponent } from './dialog-title/dialog-title.component';
import { DialogActionsComponent } from './dialog-actions/dialog-actions.component';
import { DialogContentComponent } from './dialog-content/dialog-content.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { DialogService } from '@app/core/services/dialog.service';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    DialogTitleComponent,
    DialogActionsComponent,
    DialogContentComponent,
  ],
  imports: [CommonModule, DialogModule, NgScrollbarModule],
  exports: [
    DialogTitleComponent,
    DialogActionsComponent,
    DialogContentComponent,
  ],
  providers: [
    DialogService,
    {
      provide: DEFAULT_DIALOG_CONFIG,
      useValue: {
        panelClass: 'dialog',
        maxHeight: '90vh',
        maxWidth: '90vw',
        hasBackdrop: true,
      } as DialogConfig,
    },
  ],
})
export class DialogsModule {}
