import { Component } from '@angular/core';

@Component({
  selector: 'app-title-control',
  standalone: true,
  imports: [],
  templateUrl: './title-control.component.html',
  styleUrl: './title-control.component.scss'
})
export class TitleControlComponent {

}
