import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractControlComponent } from '../abstract-control/abstract-control.component';
import { ErrorsService } from '@app/core/services/errors.service';
import { ControlErrorComponent } from '@app/components/control-error/control-error.component';

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [FormsModule, CommonModule, ControlErrorComponent],
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputComponent,
    },
  ],
})
export class InputComponent
  extends AbstractControlComponent<string>
  implements AfterViewInit
{
  @Input() formControlName!: string;
  @Input() type = 'text';
  @Input() ghost = false;
  @Input() placeholder = 'Type here...';
  @Input() size: 'default' | 'small' | 'large' = 'default';
  @Input() focus: boolean = false;

  @ViewChild(ControlErrorComponent)
  private _controlError!: ControlErrorComponent;
  @ViewChild('input') private _inputEl!: ElementRef<HTMLInputElement>;
  @Output() enter: EventEmitter<string> = new EventEmitter<string>();

  constructor(private _errorsService: ErrorsService) {
    super();
  }

  public get errors$() {
    return this._errorsService.errors$;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.focus && this._inputEl) {
        // this._inputEl.nativeElement.focus();
      }
    }, 20);
  }

  onFocusOut() {
    this._controlError.clear();
  }
}
